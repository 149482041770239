<template>
  <div id="nav">
    <nav class="navbar navbar-expand-lg py-3">
      <div class="container">
        <router-link to="/" class="navbar-brand">
          <img
            src="../src/assets/logo.png"
            alt="Logo"
            width="80"
            height="80"
            class="d-inline-block align-text-top"
          />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/projects" class="nav-link"
                >Projects</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/designs" class="nav-link">Designs</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/about-us" class="nav-link"
                >About Us</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/contact-us" class="nav-link"
                >Contact Us</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
  <router-view />
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <p class="mb-2">
            Fell free to contact Aknan company
            <a class="call" href="tel:+233508358865">+233 50 835 8865</a>
          </p>
        </div>
        <div class="col-md-6">
          <p>
            built with
            <svg
              width="20px"
              height="20px"
              viewBox="0 -5.37 77.646 77.646"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              fill="#000000"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <defs>
                  <linearGradient
                    id="linear-gradient"
                    x1="1.044"
                    y1="0.005"
                    x2="0.413"
                    y2="0.749"
                    gradientUnits="objectBoundingBox"
                  >
                    <stop offset="0" stop-color="#ff7471"></stop>
                    <stop offset="1" stop-color="#ff5245"></stop>
                  </linearGradient>
                </defs>
                <g
                  id="heart_red"
                  data-name="heart red"
                  transform="translate(-263.982 -435.283)"
                >
                  <g id="Group_25" data-name="Group 25">
                    <path
                      id="Path_69"
                      data-name="Path 69"
                      d="M302.81,446.03c-.059-.106-.128-.2-.187-.307.059.1.128.2.187.307Z"
                      fill="none"
                    ></path>
                    <path
                      id="Path_70"
                      data-name="Path 70"
                      d="M341.628,456.395l-.025-.006c.006-.142.025-.279.025-.431a20.662,20.662,0,0,0-37.039-12.611.171.171,0,0,0-.024-.007,2.169,2.169,0,0,1-3.54-.046l-.035.008a20.657,20.657,0,0,0-37,12.656c0,.147.018.282.018.424l-.029.013s0,.5.1,1.413a20.552,20.552,0,0,0,.6,3.364c1.608,6.945,6.938,20.286,24.659,32.122,10.242,6.879,12.73,8.743,13.383,8.867.031.006.048.033.083.033s.058-.033.094-.043c.7-.162,3.265-2.071,13.359-8.857,16.931-11.313,22.555-24,24.428-31.163a20.743,20.743,0,0,0,.854-4.546C341.623,456.824,341.628,456.395,341.628,456.395ZM302.81,446.03h0c-.059-.1-.128-.2-.187-.307C302.682,445.825,302.751,445.924,302.81,446.03Z"
                      fill="#ff5245"
                    ></path>
                  </g>
                  <path
                    id="Path_71"
                    data-name="Path 71"
                    d="M295.337,474.437c-5.407-20.228,1.411-28.894,5-31.889a20.747,20.747,0,0,0-6.426-5.077c-6.5-1.416-15.583.295-21.458,16.921-1,3.4-1.458,11.938-.492,22.426a65.334,65.334,0,0,0,17.38,16.476c10.242,6.879,12.73,8.743,13.383,8.867.031.006.048.033.083.033s.058-.033.094-.043a2.946,2.946,0,0,0,.76-.373C301.6,496.005,298.749,487.182,295.337,474.437Z"
                    fill="url(#linear-gradient)"
                  ></path>
                </g>
              </g>
            </svg>
            by Fouda Tech
            <a class="call" href="https://wa.me/233203499903"
              >+233 20 349 99035</a
            >
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.handleVisibilty(
        document.getElementById("service1"),
        "animate__pulse"
      );

      this.handleVisibilty(
        document.getElementById("service2"),
        "animate__pulse"
      );

      this.handleVisibilty(
        document.getElementById("solution-1"),
        "animate__pulse"
      );

      this.handleVisibilty(
        document.getElementById("solution-2"),
        "animate__pulse"
      );

      this.handleVisibilty(
        document.getElementById("project-1"),
        "animate__pulse"
      );

      this.handleVisibilty(
        document.getElementById("project-2"),
        "animate__pulse"
      );

      this.handleVisibilty(
        document.getElementById("desgin-1"),
        "animate__pulse"
      );

      this.handleVisibilty(
        document.getElementById("desgin-2"),
        "animate__pulse"
      );
    },
    handleVisibilty(ele, animationClass) {
      const item = ele.getBoundingClientRect();
      var isVisible =
        item.top >= 0 &&
        item.left >= 0 &&
        item.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        item.right <=
          (window.innerWidth || document.documentElement.clientWidth);

      if (isVisible) {
        if (!ele.classList.value.includes(animationClass)) {
          ele.classList.add(animationClass);
        }
      }
    },
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  background-color: #0e1111;
}

.navbar {
  padding: 30px;
}

.navbar a {
  font-weight: bold;
  color: #fff;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.nav-link:focus,
.nav-link:hover {
  color: #fff !important;
}

.navbar a.router-link-exact-active:not(.navbar-brand) {
  background-color: #fff;
  border-radius: 25px;
  transition: 0.5s ease-in border-radius;
  color: #2c3e50 !important;
}

.navbar,
.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-brand {
  border-radius: 15px;
  background-color: #fff;
  margin-bottom: 11px;
}

.navbar .navbar-toggler {
  background-color: #fff;
}

.btn-beig {
  --bs-btn-color: #212529 !important;
  --bs-btn-bg: rgb(255, 227, 194) !important;
  --bs-btn-border-color: rgb(255, 227, 194) !important;
  --bs-btn-hover-color: #212529 !important;
  --bs-btn-hover-bg: rgb(255, 227, 194) !important;
  --bs-btn-hover-border-color: rgb(55, 59, 62) !important;
  --bs-btn-focus-shadow-rgb: 255, 223, 186 !important;
  --bs-btn-active-color: #212529 !important;
  --bs-btn-active-bg: rgb(255, 255, 255) !important;
  --bs-btn-active-border-color: rgb(55, 59, 62) !important;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020 !important;
  --bs-btn-disabled-color: #212529 !important;
  --bs-btn-disabled-bg: rgb(255, 227, 194) !important;
  --bs-btn-disabled-border-color: rgb(255, 227, 194) !important;

  padding: 12px 40px;
}

.box-shadow {
  box-shadow: rgba(255, 255, 255, 0.4) 5px 5px,
    rgba(255, 255, 255, 0.3) 10px 10px;
}

.inner-projects .box-shadow {
  box-shadow: rgba(255, 255, 255, 0.4) 5px 5px;
}

.call {
  color: #fff;
  font-size: 18px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-family: "Rajdhani", sans-serif;
  font-weight: 500;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.section-margin {
  margin-top: 80px;
}

.section-title-margin {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 560px) {
  .section-margin {
    margin-top: 40px;
  }

  .section-title-margin {
    margin-bottom: 15px;
  }
}

footer {
  padding: 20px;
  border-top: 2px solid;
}

footer p {
  margin: 0;
}
</style>
