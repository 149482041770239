import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import AboutUs from "../views/AboutUs.vue";
import ContactUs from "../views/ContactUs.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/projects",
    name: "projects",
    component: function () {
      return import("../views/Projects.vue");
    },
  },
  {
    path: "/designs",
    name: "designs",
    component: function () {
      return import("../views/Designs.vue");
    },
  },
  {
    path: "/about-us",
    name: "aboutUs",
    component: AboutUs,
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: ContactUs,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
