<template>
  <div class="Contact-us">
    <section class="img-holder">
      <div class="layout">
        <section class="contact-us">
          <div class="container">
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-12 col-md-8">
                <div class="dislogan animate__animated animate__flash">
                  <h3 class="mb-1">
                    So we can convert your idea to sweet home.
                  </h3>
                  <p>
                    Aknan is a premier construction company located in Accra,
                    dedicated to building fancy homes and delivering exceptional
                    interior design services. With 10 years of experience and a
                    passionate team and a commitment to quality, we have
                    established ourselves as a leader in the construction
                    industry, transforming visions into reality. Our values
                    revolve around integrity, innovation, and excellence,
                    ensuring every project reflects our dedication to
                    craftsmanship and customer satisfaction.
                  </p>
                  <div class="text-center">
                    <router-link to="/contact-us" class="btn btn-beig"
                      >Contact Us</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<style scoped>
.img-holder {
  width: 100%;
  height: 90vh;
  background-image: url("../assets/about-us.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.img-holder .layout {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  justify-content: start;
  align-items: center;
}

.img-holder .layout .dislogan {
  background-color: #ffffffbd;
  padding: 15px;
  border-radius: 25px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  color: #2c3e50;
}

.img-holder .layout .dislogan p {
  font-size: 25px;
}

@media (max-width: 560px) {
  .img-holder .layout .dislogan p {
    font-size: unset;
  }
}

.contact-us {
  text-align: start;
  width: 100%;
}

.contact-us h5 {
  margin-bottom: 25px;
}

.contact-us h5 span {
  border-bottom: 3px solid #ffe3c2;
  padding-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
}

.contact-us h3 {
  margin-bottom: 20px;
}

.contact-us p {
  line-height: 2;
}

.contact-us p a {
  margin-top: 20px;
  display: inline-block;
}

.contact-us .img-top {
  border: 2px solid #fff;
  border-radius: 25px;
  padding: 3px;
}

footer {
  margin-top: 0;
}
</style>
