<template>
  <div class="home">
    <StartImage parent="home"></StartImage>

    <section class="services section-margin">
      <div class="container">
        <h3 class="title">
          <span>Our Service</span>
        </h3>
        <div class="row">
          <div class="col-md-6 col-lg-4">
            <div class="card box-shadow mb-4 animate__animated" id="service1">
              <img
                src="../assets/buy.jpg"
                loading="lazy"
                class="card-img-top"
              />
              <div class="card-body">
                <h4 class="m-0">We Buy</h4>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4">
            <div class="card box-shadow mb-4">
              <img
                src="../assets/rent.jpg"
                loading="lazy"
                class="card-img-top"
              />
              <div class="card-body">
                <h4 class="m-0">We Rent</h4>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4">
            <div class="card box-shadow mb-4 animate__animated" id="service2">
              <img
                src="../assets/develop.jpg"
                loading="lazy"
                class="card-img-top"
              />
              <div class="card-body">
                <h4 class="m-0">We develop</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about-us section-margin">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h5>
              <span>About Us</span>
            </h5>
            <h3 class="section-title-margin">
              Our journey in construction excellence
            </h3>
            <p>
              Aknan is a premier construction company located in Accra,
              dedicated to building fancy homes and delivering exceptional
              interior design services. With a passionate team and a commitment
              to quality, we have established ourselves as a leader in the
              construction industry, transforming visions into reality. Our
              values revolve around integrity, innovation, and excellence,
              ensuring every project reflects our dedication to craftsmanship
              and customer satisfaction.
              <br />
              <router-link to="/about-us" class="btn btn-beig"
                >About Us</router-link
              >
            </p>
          </div>
          <div class="col-md-6">
            <div class="img-top">
              <div class="img-holder"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="services section-margin">
      <div class="container">
        <h5>
          <span>Our Solutions</span>
        </h5>
        <h3 class="section-title-margin">
          Comprehensive solutions for your needs
        </h3>
        <div class="row">
          <div class="col-md-6 col-lg-4">
            <div class="card box-shadow mb-4 animate__animated" id="solution-1">
              <img
                src="../assets/service6.jpg"
                loading="lazy"
                class="card-img-top"
              />
              <div class="card-body">
                <h4>Home Construction</h4>
                <p class="card-text">
                  Crafting luxurious and modern homes tailored to your needs.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4">
            <div class="card box-shadow mb-4">
              <img
                src="../assets/service1.webp"
                loading="lazy"
                class="card-img-top"
              />
              <div class="card-body">
                <h4>Interior Design</h4>
                <p class="card-text">
                  Elevating spaces with innovative and stylish interior desings.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4">
            <div class="card box-shadow mb-4 animate__animated" id="solution-2">
              <img
                src="../assets/service5.jpeg"
                loading="lazy"
                class="card-img-top"
              />
              <div class="card-body">
                <h4>Renovation Services</h4>
                <p class="card-text">
                  Revitalizing spaces through expert renovation solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="projects section-margin">
      <div class="container">
        <h5>
          <span>Our Projects</span>
        </h5>
        <h3 class="section-title-margin">Some of our projects.</h3>
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-4">
            <div class="card box-shadow mb-4 animate__animated" id="project-1">
              <img
                src="../assets/project5.jpg"
                loading="lazy"
                class="card-img"
              />
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-4">
            <div class="card box-shadow mb-4">
              <img
                src="../assets/project6.jpg"
                loading="lazy"
                class="card-img"
              />
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-4">
            <div class="card box-shadow mb-4 animate__animated" id="project-2">
              <img
                src="../assets/project7.jpg"
                loading="lazy"
                class="card-img"
              />
            </div>
          </div>
        </div>

        <div>
          <router-link to="/projects" class="btn btn-beig mt-4"
            >All Projects</router-link
          >
        </div>
      </div>
    </section>

    <section class="projects section-margin">
      <div class="container">
        <h5>
          <span>Our Designs</span>
        </h5>
        <h3 class="section-title-margin">Some of our desings.</h3>
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-4">
            <div class="card box-shadow mb-4 animate__animated" id="desgin-1">
              <img
                src="../assets/design0.jpeg"
                loading="lazy"
                class="card-img"
              />
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-4">
            <div class="card box-shadow mb-4">
              <img
                src="../assets/design1.jpeg"
                loading="lazy"
                class="card-img"
              />
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-4">
            <div class="card box-shadow mb-4 animate__animated" id="desgin-2">
              <img
                src="../assets/design4.jpeg"
                loading="lazy"
                class="card-img"
              />
            </div>
          </div>
        </div>

        <div>
          <router-link to="/projects" class="btn btn-beig mt-4"
            >All Designs</router-link
          >
        </div>
      </div>
    </section>

    <section class="contact-us mb-5 section-margin">
      <div class="container">
        <div class="row">
          <div class="col-md-6 d-none d-md-block">
            <div class="img-top">
              <div class="img-holder"></div>
            </div>
          </div>

          <div class="col-md-6">
            <h5>
              <span>Contact Us</span>
            </h5>
            <h3 class="section-title-margin">
              So we can add your home picture here.
            </h3>
            <ul class="list-group">
              <li class="list-group-item d-flex align-items-center px-0">
                <span class="badge ps-0"
                  ><svg
                    height="35px"
                    width="35px"
                    viewBox="0 0 1024 1024"
                    class="icon"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#000000"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M128 768V170.666667c0-46.933333 38.4-85.333333 85.333333-85.333334h298.666667c46.933333 0 85.333333 38.4 85.333333 85.333334v597.333333c0 46.933333-38.4 85.333333-85.333333 85.333333H213.333333c-46.933333 0-85.333333-38.4-85.333333-85.333333z"
                        fill="#37474F"
                      ></path>
                      <path
                        d="M512 149.333333H213.333333c-12.8 0-21.333333 8.533333-21.333333 21.333334v533.333333c0 12.8 8.533333 21.333333 21.333333 21.333333h298.666667c12.8 0 21.333333-8.533333 21.333333-21.333333V170.666667c0-12.8-8.533333-21.333333-21.333333-21.333334z"
                        fill="#BBDEFB"
                      ></path>
                      <path
                        d="M298.666667 768h128v42.666667h-128z"
                        fill="#78909C"
                      ></path>
                      <path
                        d="M426.666667 853.333333V256c0-46.933333 38.4-85.333333 85.333333-85.333333h298.666667c46.933333 0 85.333333 38.4 85.333333 85.333333v597.333333c0 46.933333-38.4 85.333333-85.333333 85.333334H512c-46.933333 0-85.333333-38.4-85.333333-85.333334z"
                        fill="#E38939"
                      ></path>
                      <path
                        d="M810.666667 234.666667H512c-12.8 0-21.333333 8.533333-21.333333 21.333333v533.333333c0 12.8 8.533333 21.333333 21.333333 21.333334h298.666667c12.8 0 21.333333-8.533333 21.333333-21.333334V256c0-12.8-8.533333-21.333333-21.333333-21.333333z"
                        fill="#FFF3E0"
                      ></path>
                      <path
                        d="M661.333333 874.666667m-32 0a32 32 0 1 0 64 0 32 32 0 1 0-64 0Z"
                        fill="#A6642A"
                      ></path>
                    </g></svg
                ></span>
                <a class="call" href="tel:+233508358865">+233 50 835 8865</a>
              </li>

              <li class="list-group-item d-flex align-items-center px-0">
                <span class="badge ps-0"
                  ><svg
                    height="35px"
                    width="35px"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16 31C23.732 31 30 24.732 30 17C30 9.26801 23.732 3 16 3C8.26801 3 2 9.26801 2 17C2 19.5109 2.661 21.8674 3.81847 23.905L2 31L9.31486 29.3038C11.3014 30.3854 13.5789 31 16 31ZM16 28.8462C22.5425 28.8462 27.8462 23.5425 27.8462 17C27.8462 10.4576 22.5425 5.15385 16 5.15385C9.45755 5.15385 4.15385 10.4576 4.15385 17C4.15385 19.5261 4.9445 21.8675 6.29184 23.7902L5.23077 27.7692L9.27993 26.7569C11.1894 28.0746 13.5046 28.8462 16 28.8462Z"
                        fill="#BFC8D0"
                      ></path>
                      <path
                        d="M28 16C28 22.6274 22.6274 28 16 28C13.4722 28 11.1269 27.2184 9.19266 25.8837L5.09091 26.9091L6.16576 22.8784C4.80092 20.9307 4 18.5589 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z"
                        fill="url(#paint0_linear_87_7264)"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 18.5109 2.661 20.8674 3.81847 22.905L2 30L9.31486 28.3038C11.3014 29.3854 13.5789 30 16 30ZM16 27.8462C22.5425 27.8462 27.8462 22.5425 27.8462 16C27.8462 9.45755 22.5425 4.15385 16 4.15385C9.45755 4.15385 4.15385 9.45755 4.15385 16C4.15385 18.5261 4.9445 20.8675 6.29184 22.7902L5.23077 26.7692L9.27993 25.7569C11.1894 27.0746 13.5046 27.8462 16 27.8462Z"
                        fill="white"
                      ></path>
                      <path
                        d="M12.5 9.49989C12.1672 8.83131 11.6565 8.8905 11.1407 8.8905C10.2188 8.8905 8.78125 9.99478 8.78125 12.05C8.78125 13.7343 9.52345 15.578 12.0244 18.3361C14.438 20.9979 17.6094 22.3748 20.2422 22.3279C22.875 22.2811 23.4167 20.0154 23.4167 19.2503C23.4167 18.9112 23.2062 18.742 23.0613 18.696C22.1641 18.2654 20.5093 17.4631 20.1328 17.3124C19.7563 17.1617 19.5597 17.3656 19.4375 17.4765C19.0961 17.8018 18.4193 18.7608 18.1875 18.9765C17.9558 19.1922 17.6103 19.083 17.4665 19.0015C16.9374 18.7892 15.5029 18.1511 14.3595 17.0426C12.9453 15.6718 12.8623 15.2001 12.5959 14.7803C12.3828 14.4444 12.5392 14.2384 12.6172 14.1483C12.9219 13.7968 13.3426 13.254 13.5313 12.9843C13.7199 12.7145 13.5702 12.305 13.4803 12.05C13.0938 10.953 12.7663 10.0347 12.5 9.49989Z"
                        fill="white"
                      ></path>
                      <defs>
                        <linearGradient
                          id="paint0_linear_87_7264"
                          x1="26.5"
                          y1="7"
                          x2="4"
                          y2="28"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#5BD066"></stop>
                          <stop offset="1" stop-color="#27B43E"></stop>
                        </linearGradient>
                      </defs>
                    </g></svg
                ></span>
                <a class="call" href="https://wa.me/233508358865"
                  >+233 50 835 8865</a
                >
              </li>

              <li class="list-group-item d-flex align-items-center px-0">
                <span class="badge ps-0"
                  ><svg
                    height="35px"
                    width="35px"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 512.001 512.001"
                    xml:space="preserve"
                    fill="#000000"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        style="fill: #ffda79"
                        d="M494.933,506.55H17.067C7.641,506.55,0,498.909,0,489.484V176.399 c0-9.426,7.641-17.067,17.067-17.067h477.867c9.426,0,17.067,7.641,17.067,17.067v313.086 C512,498.909,504.359,506.55,494.933,506.55z"
                      ></path>
                      <path
                        style="fill: #ffd155"
                        d="M494.933,159.331H256V506.55h238.933c9.426,0,17.067-7.641,17.067-17.066V176.397 C512,166.973,504.359,159.331,494.933,159.331z"
                      ></path>
                      <path
                        style="fill: #ffc72d"
                        d="M505.644,164.33C418.806,107.433,330.422,49.53,268.885,9.192c-7.824-5.125-17.945-5.125-25.769,0 C181.212,49.77,93.315,107.353,6.356,164.33H25.85c160.326,114.396,113.889,82.376,217.267,150.14 c7.822,5.125,17.945,5.125,25.769,0c103.373-67.759,56.942-35.744,217.267-150.14L505.644,164.33L505.644,164.33z"
                      ></path>
                      <path
                        style="fill: #ffbe00"
                        d="M268.885,9.192c-3.911-2.562-8.398-3.844-12.885-3.844v312.964c4.487,0,8.972-1.281,12.885-3.844 c103.373-67.759,56.942-35.745,217.267-150.14h19.493C418.806,107.433,330.422,49.53,268.885,9.192z"
                      ></path>
                      <g>
                        <path
                          style="fill: #e6e7e8"
                          d="M245.734,334.806l-124.567-81.613c-5.284-3.462-8.468-9.352-8.468-15.67V74.183 c0-10.345,8.387-18.732,18.732-18.732H380.57c10.345,0,18.732,8.387,18.732,18.732v163.342c0,6.316-3.183,12.208-8.468,15.67 l-124.567,81.613C260.031,338.891,251.969,338.891,245.734,334.806z"
                        ></path>
                        <path
                          style="fill: #e6e7e8"
                          d="M380.57,55.452H256V337.87c3.574,0,7.149-1.02,10.266-3.063l124.567-81.613 c5.284-3.46,8.468-9.352,8.468-15.668V74.183C399.301,63.837,390.914,55.452,380.57,55.452z"
                        ></path>
                      </g>
                      <g>
                        <path
                          style="fill: #65c6bb"
                          d="M299.596,177.38h-87.191c-10.345,0-18.732-8.387-18.732-18.732s8.387-18.732,18.732-18.732h87.191 c10.345,0,18.732,8.387,18.732,18.732S309.941,177.38,299.596,177.38z"
                        ></path>
                        <path
                          style="fill: #65c6bb"
                          d="M299.596,239.014h-87.191c-10.345,0-18.732-8.387-18.732-18.732s8.387-18.732,18.732-18.732h87.191 c10.345,0,18.732,8.387,18.732,18.732C318.328,230.628,309.941,239.014,299.596,239.014z"
                        ></path>
                      </g>
                      <path
                        style="fill: #ffd155"
                        d="M512,176.399c0-9.426-7.641-17.067-17.067-17.067h-5.093L256,312.537L22.16,159.331 C6.266,159.331,0,161.956,0,185.621l150.434,98.56L0,483.772v5.711c0,9.426,7.641,17.067,17.067,17.067h8.508l153.473-203.622 l67.599,44.289c5.681,3.721,13.026,3.721,18.707,0l67.599-44.289L486.426,506.55c3.168,0,25.574,2.496,25.574-17.067v-5.712 l-150.434-199.59L512,185.621V176.399z"
                      ></path>
                      <path
                        style="fill: #ffc72d"
                        d="M512,176.397c0-9.426-7.641-17.067-17.067-17.067h-5.093L256,312.537v37.47 c3.257,0,6.514-0.93,9.353-2.791l67.599-44.289L486.426,506.55c3.168,0,25.574,2.496,25.574-17.066v-5.712L361.566,284.181 L512,185.621V176.397z"
                      ></path>
                      <g>
                        <path
                          style="fill: #20b8ae"
                          d="M299.596,139.917H256v37.463h43.596c10.345,0,18.732-8.387,18.732-18.732 C318.328,148.302,309.941,139.917,299.596,139.917z"
                        ></path>
                        <path
                          style="fill: #20b8ae"
                          d="M299.596,201.55H256v37.463h43.596c10.345,0,18.732-8.387,18.732-18.732 S309.941,201.55,299.596,201.55z"
                        ></path>
                      </g>
                    </g></svg
                ></span>
                salesdirector@aknan.ltd
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import StartImage from "../components/StartImage.vue";

export default {
  name: "Home",
  components: {
    StartImage,
  },
};
</script>

<style scoped>
.home .about-us,
.home .contact-us {
  text-align: start;
}

.home .about-us h5,
.home .contact-us h5,
.home .services .title,
.home .services h5,
.home .projects h5 {
  margin-bottom: 25px;
}

.home .about-us h5 span,
.home .services .title span,
.home .services h5 span,
.home .contact-us h5 span,
.home .projects h5 span {
  border-bottom: 3px solid #ffe3c2;
  padding-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
}

.home .about-us p,
.home .contact-us p {
  line-height: 2;
}

.home .about-us p a,
.home .contact-us p a {
  margin-top: 20px;
  display: inline-block;
}

.home .about-us .img-top,
.home .contact-us .img-top {
  border: 2px solid #fff;
  border-radius: 25px;
  padding: 3px;
}

.home .about-us .img-top .img-holder,
.home .contact-us .img-top .img-holder {
  background-image: url("../assets/about-home1.jpg");
  height: 350px;
  overflow: hidden;
  border-radius: 25px;
  background-size: cover;
}

.home .services h4,
.home .projects h4 {
  margin-bottom: 20px;
}

.home .services .card-img-top {
  height: 295px;
}

.home .contact-us .img-top .img-holder {
  background-image: url("../assets/home1.jpg");
}

.list-group-item {
  background-color: inherit;
  border: 0;
  color: inherit;
}
</style>
